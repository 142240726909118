import { Controller } from "stimulus"

export default class extends Controller {
  static values = { hours: Number, minutes: Number, seconds: Number }

  initialize() {
    this.update = this.update.bind(this)
  }

  connect() {
    this.timer = window.setTimeout(this.update, 500);
  }

  disconnect() {
    window.clearTimeout(this.timer);
  }

  update() {
    const now = new Date();
    this.hoursValue = now.getHours();
    this.minutesValue = this.getZeroPad(now.getMinutes());
    this.secondsValue = this.getZeroPad(now.getSeconds());
    this.timer = window.setTimeout(this.update, 500);

    this.element.textContent = `${this.hoursValue}:${this.minutesValue}:${this.secondsValue}`
  }

  getZeroPad(n) {
    return (parseInt(n, 10) >= 10 ? '' : '0') + n
  }
}