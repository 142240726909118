import { Controller } from "stimulus"
import Croppie from 'croppie'
import 'croppie/croppie.css'

export default class extends Controller {
  static targets = ["fileinput", "cropx", "cropy", "cropw", "croph", "cropr", "preview", "modal", "croppie", "croppiewrapper"]

  connect() {
    this.modalTarget.addEventListener('shown.bs.modal', (ev) => {
      this.croppie = new Croppie(this.croppieTarget, {
        viewport: { width: 200, height: 200, type: 'circle' },
        showZoomer: true,
        enableresize: false,
        enableOrientation: true
      })
      this.croppieTarget.addEventListener('update', (ev) => {
        let points = ev.detail.points
        this.cropxTarget.value = points[0]
        this.cropyTarget.value = points[1]
        this.cropwTarget.value = points[2] - points[0]
        this.crophTarget.value = points[3] - points[1]
      })
    })
  }

  choose() {
    if(this.fileinputTarget.files && this.fileinputTarget.files[0]) {
      let r = new FileReader
      r.onload = (e) => {
        this.croppieTarget.classList.remove("d-none")
        this.croppiewrapperTarget.classList.remove("d-none")
        this.croppie.bind({ url: e.target.result, orientation: 1 })
      }
      r.readAsDataURL(this.fileinputTarget.files[0])
    }
  }

  confirm() {
    if(this.fileinputTarget.files && this.fileinputTarget.files[0]) {
      this.croppie.result({ type: 'canvas', circle: true }).then((res) => {
        this.previewTarget.src = res
      })
    }
  }
}