import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["edit", "form", "body", "textarea"]

  edit(event) {
    event.preventDefault()
    this.editTarget.classList.add('d-none')
    this.bodyTarget.classList.add('d-none')
    this.formTarget.classList.remove('d-none')
    this.textareaTarget.style.height = "1px"
    this.textareaTarget.style.height = `${25 + this.textareaTarget.scrollHeight}px`
  }
}