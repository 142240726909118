import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
  connected() {},

  disconnected() {},

  received(data) {
    console.log(data)
    if (data['html'] === '-' && data['unreads'] === 0) {
      document.getElementById('notifications-icon').classList.remove('unread')
      document.getElementById('notifications-count').innerHTML = data['unreads']
      document.getElementById('notifications-list').innerHTML = ''
    } else {
      document.getElementById('notifications-icon').classList.add('unread')
      document.getElementById('notifications-count').innerHTML = data['unreads']
      const new_html = `<li class='list-group-item'>${data['html']}</li>`
      document.getElementById('notifications-list').insertAdjacentHTML('afterbegin', new_html)
    }
  }
});
