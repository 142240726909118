import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['replace'];
  static values = { url: String };

  change(event) {
    const url = new URL(this.urlValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append('q', event.detail.value)
    url.search = params.toString()

    fetch(url.toString())
    .then(response => response.json())
    .then(result => {
      this.replaceTargets.forEach(element => {
        if (element.dataset.infoFillerName) {
          element.innerHTML = result[element.dataset.infoFillerName]
        }

        if (element.dataset.infoFillerAttribute) {
          let [key, attr] = element.dataset.infoFillerAttribute.split(',')
          element.setAttribute(key.trim(), result[attr.trim()]);
        }

      })
    })
    .catch(() => {
      this.element.dispatchEvent(new CustomEvent('error'))
    })
  }
}