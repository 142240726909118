// Create buttons for deleting and adding nested associations

document.addEventListener('click', (event) => {
  if(event.target.matches('a[add-association]')) {
    event.preventDefault()
    let adding = event.target.attributes.append.value.replace(/new_record/g, new Date().getTime())
    let appendage = document.getElementById(event.target.attributes["append-to"].value)
    let appending = document.createElement('div')
    appending.innerHTML = adding
    appending = appending.firstChild
    appending.style.display = 'none'
    appendage.appendChild(appending)
    appending.slideDown()
  }

  if(event.target.matches('a[remove-association]')) {
    event.preventDefault()
    let destroying_target = event.target.attributes.destroy ? event.target.attributes.destroy.value : 'nested-fields'
    let destroy_input_target = event.target.attributes['destroy-input'] ? event.target.attributes['destroy-input'].value : 'destroy'
    let destroying = event.target.closest('.' + destroying_target)
    let destroy_input = destroying.querySelector('input.' + destroy_input_target)
    destroy_input.attributes.value.value = true
    destroying.slideUp()
  }

  if(event.target.matches('a[delete-association]')) {
    event.preventDefault()
    let destroying_target = event.target.attributes.destroy ? event.target.attributes.destroy.value : 'nested-fields'
    let destroying = event.target.closest('.' + destroying_target)
    destroying.slideUp()
    setTimeout(() => destroying.remove(), 1000);
  }
})