import { Controller } from "stimulus"

export default class extends Controller {
  change(event) {
    const url = new URL(this.data.get('src'), window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append('q', event.detail.value)
    url.search = params.toString()

    const check = JSON.parse(this.data.get('per-class-price'))
    const transaction_element = document.getElementById(this.data.get('transaction-id'))

    fetch(url.toString())
    .then(response => response.json())
    .then(result => {
      transaction_element.value = check[result['attending_class']]
    })
    .catch(() => {
      this.element.dispatchEvent(new CustomEvent('error'))
    })
  }
}