import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fiscalCode", "form", "hidden"];

  send(ev) {
    ev.preventDefault();
    const fiscalCode = this.fiscalCodeTarget.value;
    this.hiddenTarget.value = fiscalCode;
    this.formTarget.submit();
  }
}