import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = ["input", "output"]
  static values = { sided: Boolean, url: String, isShown: Boolean }

  connect() {
    this.onInputChange = debounce(this.onInputChange.bind(this), 400)
    this.inputTarget.addEventListener('input', this.onInputChange)
  }

  disconnect() {
    this.inputTarget.removeEventListener('input', this.onInputChange)
  }

  preview(ev) {
    ev.preventDefault()
    this.isShownValue = !this.isShownValue
    if (this.isShownValue) {
      this.onInputChange()
      this.inputTarget.classList.add('d-none')
      this.outputTarget.classList.remove('d-none')
    } else {
      this.inputTarget.classList.remove('d-none')
      this.outputTarget.classList.add('d-none')
    }
  }

  onInputChange() {
    if (this.sidedValue || this.isShownValue) {
      let formData = new FormData();
      formData.append('text', this.inputTarget.value);

      Rails.ajax({
        type: "POST",
        url: this.urlValue,
        data: formData,
        success: (_data, _status, xhr) => {
          this.outputTarget.innerHTML = xhr.response
        }
      })
    }
  }
}