import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["progress", "box"]
  static values = { variant: String, duration: Number, message: String, dismissCountDW: Number }

  connect() {
    this.element.classList.add('alert')
    this.element.classList.add(`alert-${this.covertedVariant}`)
    this.element.setAttribute('role', 'alert')
    this.boxTarget.innerHTML = this.messageValue
    this.progressTarget.classList.add('progress-bar')
    this.progressTarget.classList.add(`bg-${this.covertedVariant}`)
    this.progressTarget.setAttribute('role', 'progress-bar')
    this.progressTarget.style.width = '100%'
    this.durationValue = this.hasDurationValue ? this.durationValue : 10
    this.dismissCountDWValue = this.durationValue * 10
    this.timerID = setInterval(() => this.dismissCountDWValue -= 0.1, this.durationValue)
  }

  dismissCountDWValueChanged() {
    if(this.dismissCountDWValue < 0 && this.timerID) {
      window.clearInterval(this.timerID)
      this.timerID = null
      this.element.slideUp()
    } else {
      this.progressTarget.style.width = `${this.dismissCountDWValue}%`
    }
  }

  disconnect() {
    window.clearInterval(this.timerID)
    this.timerID = null
  }

  get covertedVariant() {
    let known = { "notice": "info", "alert": "danger" }
    return known[this.variantValue] || this.variantValue
  }

  get dismissPercent() {
    return (this.dismissCountDW / (this.durationValue * 10)) * 100
  }
}