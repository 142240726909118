import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["targeted"]

  update(ev) {
    let identifier = ev.target.getAttribute('data-sync-indentifier');
    this.targetedTargets.forEach(target => {
      if (target.getAttribute('data-propagateto') == identifier) {
        target.value = ev.target.value;
      }
    });
  }
}