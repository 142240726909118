import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["propagate"]

  watch(event) {
    let propagateTo = event.target.getAttribute('data-propagateto')
    let togglableClass = event.target.getAttribute('data-toggleclass')
    this.propagateTargets.forEach(target => {
      if (target.getAttribute('data-togglewatch') !== propagateTo) return
      target.classList.toggle(togglableClass)
    });
  }

  specific(event) {
    let applyTo = event.target.getAttribute('data-applyto')
    let activeValue = event.target.getAttribute('data-activevalue')
    let togglableClass = event.target.getAttribute('data-toggleclass')
    let inverse = event.target.getAttribute('data-toggleinverse') == "true"
    this.propagateTargets.forEach(target => {
      if (target.getAttribute('data-togglewatch') === applyTo) {
        if (inverse) {
          if (event.target.value === activeValue) target.classList.remove(togglableClass)
          else target.classList.add(togglableClass)
        } else {
          if (event.target.value === activeValue) target.classList.add(togglableClass)
          else target.classList.remove(togglableClass)
        }
      } else if (target.getAttribute('data-togglewatch') === `${applyTo}-inverse`) {
        if (inverse) {
          if (event.target.value === activeValue) target.classList.add(togglableClass)
          else target.classList.remove(togglableClass)
        } else {
          if (event.target.value === activeValue) target.classList.remove(togglableClass)
          else target.classList.add(togglableClass)
        }
      }
    });
  }
}