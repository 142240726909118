import { Controller } from "stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static values = {
    multiple: Boolean,
    allowNew: Boolean,
    url: String,
    options: Array,
    name: String,
    queryMinLength: Number,
    placeholder: String,
    selected: Array
  }

  initialize() {
    this._selectElement = document.createElement("div")
    this._label = document.createElement("span")
    this._dropdownWrapper = document.createElement("div")
    this._optionsWrapper = document.createElement("div")
    this._placeholder = document.createElement("span")
    this._actualInput = document.createElement("input")

    this._selectElement.classList.add("autocomplete-select__select")
    this._selectElement.setAttribute('tabindex', '0')
    this._label.classList.add("autocomplete-select__label")
    this._dropdownWrapper.classList.add("autocomplete-select__options")
    this._placeholder.classList.add("autocomplete-select__placeholder")
    this._actualInput.setAttribute('type', 'hidden')
    this._actualInput.setAttribute('name', this.nameValue)
    this._placeholder.textContent = this.placeholderValue
    
    this._autocomplete = document.createElement("input")
    this._autocomplete.classList.add("autocomplete-select__autocomplete")
    this._autocomplete.setAttribute('type', 'text')
    this._autocomplete.setAttribute('autocomplete', 'off')
    this._autocomplete.setAttribute('spellcheck', 'false')

    this.onAutocompleteInput = debounce(this.onAutocompleteInput.bind(this), 300)
    this.onClick = this.onClick.bind(this)
    this.onKeydown = this.onKeydown.bind(this)
    this.onInputBlur = this.onInputBlur.bind(this)
    this.onInputFocus = this.onInputFocus.bind(this)
    this.onResultsMouseDown = this.onResultsMouseDown.bind(this)
    
    this._dropdownWrapper.append(this._autocomplete)
    this._dropdownWrapper.append(this._optionsWrapper)
    this._selectElement.append(this._label)
    this._selectElement.append(this._dropdownWrapper)
    this._selectElement.append(this._placeholder);
    this._selectElement.append(this._actualInput);
    this.element.append(this._selectElement)

    if (this.multipleValue) {
      this._selectElement.classList.add("select-pure__select--multiple")
      this._selectElement.removeChild(this._actualInput);
    }

    if (!this.isRemote) this._options = this.generateOptions(this.optionsValue)
  }

  connect() {
    this.opened = false
    this._selectedOptions = []

    const selectedOptions = this.generateOptions(this.selectedValue)
    if (this.multipleValue) {
      selectedOptions.forEach(opt => {
        this.createOptionLabel(opt.label, opt.value)
        this.createInputNode(opt.value)
        this._selectedOptions.push(opt.value.toString())
      })
    } else {
      this._actualInput.value = (selectedOptions[0] && selectedOptions[0].value) || ""
      this._placeholder.textContent = (selectedOptions[0] && selectedOptions[0].label) || ""
    }

    this._autocomplete.addEventListener('input', this.onAutocompleteInput)
    this._autocomplete.addEventListener('keydown', this.onKeydown)
    this._autocomplete.addEventListener('blur', this.onInputBlur)
    this._selectElement.addEventListener('click', this.onClick)
    this._selectElement.addEventListener('focus', this.onInputFocus)
    this._dropdownWrapper.addEventListener('mousedown', this.onResultsMouseDown)
  }

  disconnect() {
    this._autocomplete.removeEventListener('keydown', this.onKeydown)
    this._autocomplete.removeEventListener('input', this.onAutocompleteInput)
    this._autocomplete.removeEventListener('blur', this.onInputBlur)
    this._selectElement.removeEventListener('click', this.onClick)
    this._selectElement.removeEventListener('focus', this.onInputFocus)
    this._dropdownWrapper.removeEventListener('mousedown', this.onResultsMouseDown)
  }

  hideAndRemoveOptions() {
    this.close()
    this._optionsWrapper.innerHTML = null
  }

  generateOptions(from) {
    return from.map(_option => {
      const opt = { label: "", value: "", disabled: false }

      if (_option instanceof Array) {
        opt.value = _option[0]
        opt.label = _option[1]
        opt.disabled = _option[2] === undefined ? false : _option[2]
      } else if (typeof _option == "string") {
        opt.value = _option
        opt.label = _option
      }

      return opt
    });
  }

  constructOptions(from) {
    this._optionsWrapper.innerHTML = ""
    from.forEach(opt => {
      const option = document.createElement("div")

      option.classList.add(`autocomplete-select__option${opt.disabled ? " autocomplete-select__option--disabled" : ""}`)
      option.setAttribute('value', opt.value)
      option.setAttribute('aria-disabled', opt.disabled)
      option.setAttribute('role', 'option')
      option.textContent = opt.label

      if (opt.disabled) {
        this._disabledOptions.push(String(opt.value));
      }

      this._optionsWrapper.append(option);
    })
  }

  sibling(next) {
    const options = Array.from(this._optionsWrapper.querySelectorAll('[role="option"]'))
    const selected = this._optionsWrapper.querySelector('[aria-selected="true"]')
    const index = options.indexOf(selected)
    const sibling = next ? options[index + 1] : options[index - 1]
    const def = next ? options[0] : options[options.length - 1]
    return sibling || def
  }

  select(target) {
    for (const el of this._optionsWrapper.querySelectorAll('[aria-selected="true"]')) {
      el.removeAttribute('aria-selected')
      el.classList.remove('autocomplete-select__option--selected')
    }
    target.setAttribute('aria-selected', 'true')
    target.classList.add('autocomplete-select__option--selected')
  }

  onClick(event) {
    event.stopPropagation()
    event.preventDefault()
    
    if (event.target.className == "autocomplete-select__autocomplete") return
    if (event.target.className == "autocomplete-select__selected-label") return

    if (event.target.getAttribute('role') == 'unselect-btn') {
      const selected = event.target.closest('[role="value-label"]')
      if (selected) this.uncommit(selected)
      return;
    }

    if (!this.opened) this.open()
    
    if (this.opened) {
      if (!(event.target instanceof Element)) return
      const selected = event.target.closest('[role="option"]')
      if (selected) this.commit(selected)
    }
  }

  onAutocompleteInput(event) {
    this._actualInput.setAttribute('value', "")
    this.fetchResults()
  }

  onInputBlur() {
    if (this.mouseDown) return
    this.close()
  }

  onInputFocus() {
    if (!this.opened) this.open()
  }

  onResultsMouseDown(event) {
    this.mouseDown = true
    this._dropdownWrapper.addEventListener('mouseup', () => (this.mouseDown = false), {once: true})
  }

  onKeydown(event) {
    switch (event.key) {
      case 'Escape':
        if (!this._dropdownWrapper.hidden) {
          this.hideAndRemoveOptions()
          event.stopPropagation()
          event.preventDefault()
        }
        break
      case 'ArrowDown':
        {
          const item = this.sibling(true)
          if (item) this.select(item)
          event.preventDefault()
        }
        break
      case 'ArrowUp':
        {
          const item = this.sibling(false)
          if (item) this.select(item)
          event.preventDefault()
        }
        break
      case 'Tab':
        {
          const selected = this._optionsWrapper.querySelector('[aria-selected="true"]')
          if (selected) {
            this.commit(selected)
          }
        }
        break
      case 'Enter':
        {
          const selected = this._optionsWrapper.querySelector('[aria-selected="true"]')
          if (selected && !this._optionsWrapper.hidden) {
            this.commit(selected)
            event.preventDefault()
          }
        }
        break
    }
  }

  fetchResults() {
    const query = this._autocomplete.value.trim()
    if (this.minLength && (!query || query.length < this.minLength)) {
      this.hideAndRemoveOptions()
      return
    }
    
    if (!this.isRemote) {      
      let filtered = this._options.filter((opt) => opt.label.toLowerCase().trim().indexOf(query.toLowerCase()) > -1)
      if (this.multipleValue) filtered = filtered.filter(opt => !(this._selectedOptions.indexOf(opt.value) > -1))
      if (this.allowNewValue) {
        const newvals = filtered.filter(opt => query.toLowerCase().trim().indexOf(opt.label.toLowerCase().trim()) > -1)
        if (newvals.length == 0)
          filtered.push({ label: `+ ${query}`, value: query, disabled: false })
      }
      this.constructOptions(filtered)
      return
    }

    const url = new URL(this.urlValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append('q', query)
    url.search = params.toString()

    this.element.dispatchEvent(new CustomEvent('loadstart'))

    fetch(url.toString())
      .then(response => response.json())
      .then(result => {
        let opts = this.generateOptions(result)
        if (this.multipleValue) opts = opts.filter(opt => !(this._selectedOptions.indexOf(opt.value.toString()) > -1))
        this.constructOptions(opts)
        this.element.dispatchEvent(new CustomEvent('load'))
        this.element.dispatchEvent(new CustomEvent('loadend'))
      })
      .catch(() => {
        this.element.dispatchEvent(new CustomEvent('error'))
        this.element.dispatchEvent(new CustomEvent('loadend'))
      })
  }

  commit(selected) {
    if (selected.getAttribute('aria-disabled') == 'true') return

    if (selected instanceof HTMLAnchorElement) {
      selected.click()
      this.close()
      return
    }
    
    const textValue = selected.textContent.trim()
    const value = selected.getAttribute('value') || textValue
    if (this.multipleValue) {
      this.createOptionLabel(textValue, value)
      this._autocomplete.value = ""
      this._placeholder.textContent = ""
      this._selectedOptions.push(value)
      this.createInputNode(value)
    } else {
      this._autocomplete.value = textValue
      this._placeholder.textContent = textValue
      this._actualInput.value = value
    }

    this.hideAndRemoveOptions()
    this.element.dispatchEvent(new CustomEvent('autocomplete.change', {
      bubbles: true,
      detail: { value: value, textValue: textValue }
    }))
  }

  uncommit(selected) {
    const input = this._selectElement.querySelector('[role="actual-input"][value="' + selected.getAttribute('value') + '"]')
    const idx = this._selectedOptions.indexOf(selected.getAttribute('value').toString())
    if (idx > -1) this._selectedOptions.splice(idx, 1)
    this._selectElement.removeChild(selected)
    this._selectElement.removeChild(input)
    if (this.opened) this.close()
  }

  createOptionLabel(text, value) {
    const opt = document.createElement('span')
    const btn = document.createElement('i')
    btn.innerHTML = '&#10008;'
    btn.setAttribute('role', 'unselect-btn')
    opt.textContent = text
    opt.setAttribute('role', 'value-label')
    opt.setAttribute('value', value)
    opt.appendChild(btn)
    opt.classList.add('autocomplete-select__selected-label')
    this._selectElement.append(opt)
  }

  createInputNode(value) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('value', value)
    input.setAttribute('name', `${this.nameValue}[]`)
    input.setAttribute('role', "actual-input")
    this._selectElement.append(input)
  }

  open() {
    this._selectElement.classList.add('autocomplete-select__select--opened')
    this.opened = true
    this._autocomplete.focus();
    this._autocomplete.value = ""
    if (!this.isRemote) {
      let filtered = this._options
      if (this.multipleValue) filtered = filtered.filter(opt => !(this._selectedOptions.indexOf(opt.value) > -1))
      this.constructOptions(filtered)
    }
    this.element.dispatchEvent(new CustomEvent('toggle', {detail: "autocomplete"}))
  }
  
  close() {
    this._selectElement.classList.remove('autocomplete-select__select--opened')
    this.opened = false
    this.element.dispatchEvent(new CustomEvent('toggle', {detail: "autocomplete"}))
  }

  get minLength() {
    const minLength = this.queryMinLengthValue
    if ( !minLength ) {
      return 0
    }
    return parseInt(minLength, 10)
  }

  get isRemote() {
    return this.hasUrlValue
  }
}