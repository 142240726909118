import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["p1h1", "p1h2", "p2h1", "p2h2"]

  connect() {
    this.p1h1Target.classList.remove('d-none')
    this.p1h2Target.classList.add('d-none')
    this.p2h1Target.classList.add('d-none')
    this.p2h2Target.classList.add('d-none')

    this.update1h1()
    this.update1h2()
    this.update2h1()
    this.update2h2()

    this.element.closest('form').addEventListener('submit', this._submit.bind(this))
  }

  disconnect() {
    this.element.closest('form').removeEventListener('submit', this._submit)
  }

  clearAll(ev) {
    this.p1h1Target.querySelector('select').disabled = false
    this.p1h2Target.querySelector('select').disabled = false
    this.p2h1Target.querySelector('select').disabled = false
    this.p2h2Target.querySelector('select').disabled = false

    this.p1h1Target.querySelector('select').selectedIndex = 0
    this.p1h2Target.querySelector('select').selectedIndex = 0
    this.p2h1Target.querySelector('select').selectedIndex = 0
    this.p2h2Target.querySelector('select').selectedIndex = 0

    this.p1h1Target.classList.remove('d-none')
    this.p1h2Target.classList.add('d-none')
    this.p2h1Target.classList.add('d-none')
    this.p2h2Target.classList.add('d-none')

    ev.preventDefault()
  }

  update1h1(ev) {
    const el = this.p1h1Target.querySelector('select option:checked')
    if (el.value !== "") {
      this.p1h2Target.classList.remove('d-none')
      const category = el.dataset.category
      const period = el.dataset.period
      this.p1h2Target.querySelectorAll(`select option[data-category="${category}"]`).forEach(el => el.remove())

      if (period === "full") {
        this.p2h1Target.querySelector('select').selectedIndex = 0
        this.p2h1Target.classList.add('d-none')
        this._p2h1Show = false
      } else if (period === "half") {
        this._p2h1Show = true
        let opt;
        if ((opt = this.p2h1Target.querySelector(`select option[value="${el.value}"]`))) opt.remove()
        if ((opt = this.p2h2Target.querySelector(`select option[value="${el.value}"]`))) opt.remove()
      } else {
        this._p2h1Show = true
      }
      this.p1h1Target.querySelector('select').disabled = true
    }
  }

  update1h2(ev) {
    const el = this.p1h2Target.querySelector('select option:checked')
    if (el.value !== "") {
      if (this._p2h1Show) this.p2h1Target.classList.remove('d-none')
      const period = el.dataset.period

      if (period === "full") {
        this.p2h2Target.querySelector('select').selectedIndex = 0
        this.p2h2Target.classList.add('d-none')
        this._p2h2Show = false
      } else if (period === "half") {
        this._p2h2Show = true
        let opt;
        if ((opt = this.p2h1Target.querySelector(`select option[value="${el.value}"]`))) opt.remove()
        if ((opt = this.p2h2Target.querySelector(`select option[value="${el.value}"]`))) opt.remove()
      } else {
        this._p2h2Show = true
      }

      this.p1h2Target.querySelector('select').disabled = true
      if (!this._p2h1Show && this._p2h2Show) this.p2h2Target.classList.remove('d-none')
    }
  }

  update2h1(ev) {
    const el = this.p2h1Target.querySelector('select option:checked')
    if (el.value !== "") {
      if (this._p2h2Show) this.p2h2Target.classList.remove('d-none')
      const category = el.dataset.category
      const period = el.dataset.period
      this.p2h2Target.querySelectorAll(`select option[data-category="${category}"]`).forEach(el => el.remove())
      this.p2h1Target.querySelector('select').disabled = true
    }
  }

  update2h2(ev) {
    const el = this.p2h2Target.querySelector('select option:checked')
    if (el.value !== "") {
      this.p2h2Target.querySelector('select').disabled = true
    }
  }

  _submit(ev) {
    const style = 'pointer-event:none; background: #e9ecef; color: #6c757d'
    this.p1h1Target.querySelector('select').disabled = false
    this.p1h1Target.querySelector('select').style = style
    this.p1h2Target.querySelector('select').disabled = false
    this.p1h2Target.querySelector('select').style = style
    this.p2h1Target.querySelector('select').disabled = false
    this.p2h1Target.querySelector('select').style = style
    this.p2h2Target.querySelector('select').disabled = false
    this.p2h2Target.querySelector('select').style = style
  }
}