import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['all', 'count'];

  connect() {
    this.element.addEventListener('mousedown', this._onMouseDown.bind(this));
    this.element.addEventListener('change', this._onChange.bind(this));

    this._shiftKey = false;
    this._lastCheckbox = null;

    if (this.hasAllTarget) {
      const total = this.checkboxes.length
      const count = this.checked.length
      const checked = count === total
      const indeterminate = total > count && count > 0
      this._setChecked(this.allTarget, this.allTarget, checked, indeterminate)
    }
  }
  
  disconnect() {
    this.element.removeEventListener('mousedown', this._onMouseDown);
    this.element.removeEventListener('change', this._onChange);
  }

  _onChange(ev) {
    const target = ev.target
    if (!(target instanceof Element)) return
    if (target === this.allTarget) this._onCheckAll(ev)
    else if (this.checkboxes.includes(target)) this._onCheckAllItem(ev)
  }

  _onMouseDown(ev) {
    if (!(ev.target instanceof Element)) return
    const target = ev.target instanceof HTMLLabelElement ? ev.target.control || ev.target : ev.target
    if (this.checkboxes.includes(target)) this._shiftKey = ev.shiftKey
  }

  _setChecked(target, input, checked, indeterminate = false) {
    if (!(input instanceof HTMLInputElement)) return
    input.indeterminate = indeterminate

    if (input.checked !== checked) {
      input.checked = checked

      setTimeout(() => {
        const ev = new CustomEvent('change', {
          bubbles: true,
          cancelable: true,
          detail: {relatedTarget: target}
        })

        input.dispatchEvent(ev)
      })
    }
  }

  _onCheckAll(ev) {
    if (ev instanceof CustomEvent && ev.detail) {
      const {relatedTarget} = ev.detail
      if (relatedTarget && this.checkboxes.includes(relatedTarget)) return
    }
    const target = ev.target
    if (!(target instanceof HTMLInputElement)) return
    this._lastCheckbox = null
    for (const input of this.checkboxes) this._setChecked(target, input, target.checked)
    
    target.indeterminate = false
    this._updateCount()
  }

  _onCheckAllItem(ev) {
    if (ev instanceof CustomEvent && ev.detail) {
      const {relatedTarget} = ev.detail
      if (relatedTarget && (this.checkboxes.includes(relatedTarget) || relatedTarget === this.allTarget)) return
    }
    const target = ev.target
    if (!(target instanceof HTMLInputElement)) return

    if (this._shiftKey && this._lastCheckbox) {
      const [start, end] = [this.checkboxes.indexOf(this._lastCheckbox), this.checkboxes.indexOf(target)].sort()
      for (const input of this.checkboxes.slice(start, +end + 1 || 9e9))
        this._setChecked(target, input, target.checked)
    }

    this._shiftKey = false
    this._lastCheckbox = target

    if (this.hasAllTarget) {
      const total = this.checkboxes.length
      const count = this.checked.length
      const checked = count === total
      const indeterminate = total > count && count > 0
      this._setChecked(target, this.allTarget, checked, indeterminate)
    }

    this._updateCount()
  }

  _updateCount() {
    if (this.hasCountTarget)
      this.countTarget.textContent = this.checked.length.toString()
  }

  get checkboxes() {
    const selector = 'input[type="checkbox"]:not([data-check-all-target="all"]):not([data-skip])'
    const elements = this.element.querySelectorAll(selector)
    return Array.from(elements)
  }

  get checked() {
    return this.checkboxes.filter(checkbox => checkbox.checked)
  }

  get unchecked() {
    return this.checkboxes.filter(checkbox => !checkbox.checked)
  }
}